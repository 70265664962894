<template>
  <div class="recharge-record-page">
    <div class="header-box">
      <span class="recharge-date">充值日期</span>
      <el-date-picker
        class="btn-date-picker"
        v-model="time"
        type="daterange"
        :editable="false"
        :clearable="false"
        align="center"
        range-separator="-"
        placeholder="选择日期范围"
      ></el-date-picker>
      <el-button @click="coinOrderRecords">查询</el-button>
    </div>
    <div class="table-box">
      <el-table
        :data="tableData"
        v-loading="loading"
        border
        style="width: 100%"
        :height="getStyle"
        :header-cell-style="{ background: '#eef1f6' }"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="orderNo"
          label="订单号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="paymentTime"
          label="日期"
          align="center"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="payType"
          label="类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="payAmount"
          label="充值金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="description"
          label="充值金币"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          prop="coinNum"
          label="充值金币"
          align="center"
        ></el-table-column> -->
        <el-table-column prop="paymentMethod" label="支付方式" align="center">
          <template slot-scope="scope">
            {{ getPayType(scope.row.paymentMethod) }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            {{ getStatus(scope.row.payStatus) }}
          </template>
        </el-table-column> -->
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="1"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { coinOrderRecords } from '@/api/user.js'
import { myMixin } from '@/mixins/mixin.js'
export default {
  name: 'rechargeRecord',
  components: {},
  props: {},
  mixins: [myMixin],
  data() {
    return {
      params: {
        pageNo: 1,
        pageSize: 10,
        beginDate: '',
        endDate: ''
      },
      time: [],
      tableData: [],
      total: 0,
      loading: false
    }
  },
  computed: {
    getStyle() {
      if (this.tableData.length > 0) {
        return '100%'
      } else {
        return 'auto'
      }
    }
  },
  watch: {},
  created() {
    this.time = [new Date(this.getStartDate()), new Date()]
    this.coinOrderRecords()
  },
  methods: {
    // 获取充值记录信息
    coinOrderRecords() {
      this.loading = true
      this.params.beginDate = this.format(this.time[0])
      this.params.endDate = this.format(this.time[1])
      coinOrderRecords(this.params).then(res => {
        this.loading = false
        this.tableData = res.data.data || []
        this.total = res.data.totalRecord
      })
    },
    getPayType(val) {
      const obj = {
        'WECHAT_PAY': '微信',
        'ALIPAY': '支付宝',
        'GIFT_PAY': '赠送'
      }
      return obj[val]
    },
    // getStatus(val) {
    //   const obj = {
    //     'WAITING': '待付款',
    //     'FAIL': '付款失败',
    //     'PAID': '付款成功',
    //     'CLOSE': '关闭',
    //     'REFUND_SUCCESS': '退款成功',
    //     'REFUND_FAILED': '退款失败'
    //   }
    //   return obj[val]
    // },
    handleSizeChange(val) {
      this.params.pageSize = val
      this.coinOrderRecords()
    },
    handleCurrentChange(val) {
      this.params.pageNo = val
      this.coinOrderRecords()
    }
  }
}
</script>

<style scoped lang="scss">
.recharge-record-page {
  position: relative;
  width: 1010px;
  height: 100%;
  padding: 20px;
  box-shadow: 0px 2px 12px 0px #edecf6;
  border-radius: 4px;
  .header-box {
    display: flex;
    align-items: center;
    .recharge-date {
      margin-right: 22px;
    }
    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 66px;
      height: 30px;
      background: linear-gradient(#3777ff, #0e39ff);
      border-color: #3777ff;
      color: #fff;
      margin: 0 0 0 22px;
    }

    ::v-deep.el-input__inner {
      width: 180px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
    }
  }
  ::v-deep.el-date-editor {
    width: 200px !important;
    height: 30px;
    line-height: 30px !important;
    font-size: 12px !important;

    .el-range-input {
      font-size: 12px;
    }

    .el-icon-date {
      margin-top: -8px;
      &::before {
        margin-right: -360px;
      }
    }

    .el-range__icon {
      margin-left: -22px;
    }

    .el-range-separator {
      line-height: 22px;
    }
  }
  .table-box {
    height: calc(100% - 100px);
    margin-top: 20px;
    ::v-deep.has-gutter {
      color: #373e4f;
    }
    .block {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
</style>
